









import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import PsychologistProfile from "../../../components/PsychologistProfile.vue";
import userModel from "@/models/user.model";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

@Component({
  components: {
    PsychologistProfile,
    ClipLoader,
  },
})
export default class MyProfile extends Vue {
  psychologist: any;
  psychologistId: number = null;
  loading = false;

  @Prop()
  updated: any;

  @Watch('$route')
  updateRoute(route: any) {
    this.psychologistId = parseInt(route.params.id)
    this.loadPsychologist();
  }

  mounted() {
    this.psychologistId = parseInt(this.$route.params.id)
    this.loadPsychologist();
  }

  async loadPsychologist() {
    this.loading = true;
    try {
      this.psychologist = await userModel.getPsychologist(this.psychologistId);
      //console.log(this.psychologist)
    } catch (error) {
      console.error(error);
      this.$vs.notification({
        title: "Erro",
        text: error.message,
        color: "#FF6767",
      });
    }
    this.loading = false
  }
}
